<template>
    <div class="content-wrapper">
        <!-- Main content -->
        <section class="content mt-4">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <h4 class="ml-2 card-title">RTM to Hub Return Order Details</h4>
                                    <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                                </div>
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                            <label for="date">Barcode Type or Scan</label>
                                            <input type="text" class="form-control" v-model="itemId" @keyup.enter="keepOrder" placeholder="Scan barcode or type order id and press enter"> 
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group" style="margin-top:26px">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="returnReceive">Receive</button>
                                            <button class="ml-1 btn btn-success btn-sm mr-2" @click="downloadPdf">PDF <i class="fa fa-download"></i></button>
                                            <b style="margin-left:10px; font-size:18px">Total:{{ totalParcel }}</b>
                                            <b style="margin-left:10px; font-size:18px">Scaned:{{ form.order_ids.length }}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <span v-for="(orderId, index) in form.order_ids" :key="index" class="btn btn-sm btn-warning mb-1 mr-1">
                                    {{ orderId }} <i class="ml-1 fas fa-times" @click="removeRow(orderId)"></i>
                                </span>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table id="exportPdfTable" class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Order Date</th>
                                                    <th>Customer</th>
                                                    <th>Address</th>
                                                    <th>Price (BDT)</th>
                                                    <th>Status</th>
                                                    <th>Return Status</th>
                                                    <th>Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(order, index) in listData" :key="index">
                                                    <td>{{ order.id }}</td>
                                                    <td @click="orderLog(order.logs)">{{ order.date | dateformat }}</td>
                                                    <td>{{ order.name+ ' '+ order.mobile }}</td>
                                                    <td>{{ order.address }}</td>
                                                    <td>{{ order.price }}</td>
                                                    <td><span :class="'status-'+order.status">{{ getStatus(order.status) }}</span></td>
                                                    <td><span class="status-20">{{ order.return ? order.return.operation : '' }}</span></td>
                                                    <td>{{ order.reason }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Log</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th style="width:20%">Action By</th>
                                <th style="width:55%">Action</th>
                                <th style="width:25%">Date & Time</th>
                            </tr>
                            <tr v-for="(log, index) in logs" :key="index">
                                <td>{{ log.operation_by }}</td>
                                <td>{{ log.operation }}</td>
                                <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                            </tr>
                        </table>                      
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    import 'jspdf-autotable'    
    import ExportPdf from './HubToRtm'

    const excelColumn = {
        'Order ID': 'order_id',
        'Ref. ID': 'order_ref_id',
        'Entry Date': 'order_date',
        'Last Action Date': 'order_activity_date',
        'Name': 'order_name',
        'Mobile': 'order_mobile',
        'Price': 'order_price',
        'Reason': 'order_reason',
        'Signature': 'order_sign'
    }
    export default {
        name:'MakePaymentOrder',
        data(){
            return {   
                orderLogModal:false,
                logs:[],
                loader: true,             
                listData:[],
                key:'',
                merchant: '',
                rider: '',
                totalParcel: 0,
                itemId: '',
                form: {
                    order_ids: []
                }
            }
        },
        created(){
            this.getOrders();
        },
        computed: {
            authUser () {
                return this.$store.state.authUser
            },
            json_fields: function () {
                return excelColumn
            },
            dataCustomize () {
                return this.orders.map(item => {
                    item.order_id       = item.tracking_id,
                    item.order_ref_id   = item.ref_id,
                    item.order_date     = item.date,
                    item.order_activity_date = item.activity_date,
                    item.order_name     = item.name,
                    item.order_mobile   = item.mobile,
                    item.order_price    = item.price,
                    item.order_reason   = item.reason,
                    item.order_sign     = ''

                    return Object.assign({}, item)
                })
            }
        },
        methods:{
            searchData () {
                this.getOrders()
            },
            async getOrders(){     
                this.loader = true     
                const response = await config.getData(`/return/rtm-to-hub-details/${this.$route.query.id}`)
                this.loader = false
                if (response.success){
                    this.totalParcel = response.data.length
                    this.listData = response.data
                } else {
                    this.listData = []
                } 
            },
            keepOrder() {
                const tmpItem = this.listData.find(el => el.id === parseInt(this.itemId))
                if (typeof tmpItem !== 'undefined') {
                    this.form.order_ids.push(this.itemId)
                    this.listData = this.listData.filter(el => el.id !== parseInt(this.itemId))
                    this.totalParcel -= 1
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, parcel not found',
                        color: '#dc3545'
                    })
                }  
                this.itemId = ''
            },
            removeRow(itemId){
                this.form.order_ids = this.form.order_ids.filter(el => parseInt(el) !== parseInt(itemId))
            },
            async returnReceive(){    
                this.loader = true 
                const params = { return_id: this.$route.query.id, orders: this.form.order_ids}       
                const response = await config.postData('/return/rtm-to-hub-return-receive-partial', params)
                this.loader = false
                if (response.success){
                    this.$toast.success({
                        title: 'Success',
                        message: 'Return parcel received from Hub',
                        color: '#218838'
                    })
                    this.$router.push('/rtm-to-hub')
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    }) 
                } 
            },
            async downloadPdf(){
                this.totalParcel = 0
                this.form.order_ids = []
                this.loader = true
                const response = await config.getData(`/return/rtm-to-hub-details/${this.$route.query.id}`)
                this.loader = false
                if (response.success) {
                    this.pdfDownload(response.data)   
                } else {                
                    this.$toast.error('Sorry, something went wrong')  
                }
            },
            pdfDownload(data) {
                const hub = this.$store.state.commonObj.hubList.find(el => el.id == this.authUser.hub_id)
                const hubName = hub ? hub.text : ''
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.hubToRtmPdfDetails(base64Logo, data, hubName)
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },
            orderLog (logs) {
                this.orderLogModal = true
                this.logs = logs
            },
            cancelModal () {
                this.orderLogModal = false
            },            
            getStatus (status) {
                const tmpStatus = this.$store.state.statusList.find(tmp => tmp.id == status)
                return typeof tmpStatus != 'undefined' ? tmpStatus.text : status
            }
        }
    }
</script>

<style scoped>
    .d-inline {
        display: inline-block;
        padding-left: 3px;
    }
</style>